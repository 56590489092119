import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSpringCarousel } from 'react-spring-carousel';

import { Icon } from '@fashionphile/component-library';

import { CarouselItem, CarouselItemType } from './CarouselItem';
import { ShopState } from '../../types/Store';
import {
  CAROUSEL_CARD_DESKTOP_WIDTH,
  CAROUSEL_CARD_MOBILE_WIDTH,
  CAROUSEL_MAX_WIDTH,
  PADDING,
  WIDTH,
} from '../../utilities/constants';

const ReactSpringCenterCarousel = ({
  products,
  carouselTitle,
  variant,
}: {
  products: CarouselItemType[];
  carouselTitle: string;
  variant?: 'center' | 'default';
}): React.ReactElement => {
  const pageWidth =
    useSelector(
      (state: ShopState) => state.headerFooterReducer.width,
    ) || CAROUSEL_MAX_WIDTH;
  const isMobileViewport = useSelector(
    (state: ShopState) => state.headerFooterReducer.isMobile,
  );
  const [activeItemIndex, setActiveItemIndex] = useState<number>(0);

  const showMobileCarousel = pageWidth < WIDTH.SM;
  const recentProductCardWidth = isMobileViewport
    ? CAROUSEL_CARD_MOBILE_WIDTH
    : CAROUSEL_CARD_DESKTOP_WIDTH;
  const containerPadding =
    pageWidth > WIDTH.SM ? PADDING.XXL : PADDING.XL;
  const scrollBarOffset = 15;
  const containerWidth =
    pageWidth >= CAROUSEL_MAX_WIDTH
      ? CAROUSEL_MAX_WIDTH
      : pageWidth - containerPadding - scrollBarOffset;
  const cardsOnPage = Math.floor(
    containerWidth / recentProductCardWidth,
  );
  const numberOfItemsToShow = Math.min(products.length, cardsOnPage);

  const mobileItemsToShow = Math.min(
    numberOfItemsToShow + 1,
    products.length,
  );

  const {
    carouselFragment,
    slideToPrevItem,
    slideToNextItem,
    slideToItem,
    useListenToCustomEvent,
  } = useSpringCarousel({
    itemsPerSlide: showMobileCarousel
      ? mobileItemsToShow
      : numberOfItemsToShow,
    slideType: 'fixed',
    items: products.map((product, idx) => ({
      id: `${carouselTitle.replaceAll(' ', '-')}-${idx}`,
      renderItem: (
        <CarouselItem
          key={`${carouselTitle.replaceAll(' ', '-')}-carousel-${
            product.productId || product.id
          }`}
          product={product}
          variant={variant}
        />
      ),
    })),
  });

  let leftIndex = activeItemIndex + 1;
  let potentialRightIndex =
    leftIndex +
    Math.min(products.length - 1, numberOfItemsToShow - 1);

  let rightIndex = Math.min(potentialRightIndex, products.length);

  useEffect(() => {
    leftIndex = activeItemIndex + 1;

    potentialRightIndex =
      leftIndex +
      Math.min(products.length - 1, numberOfItemsToShow - 1);

    rightIndex = Math.min(potentialRightIndex, products.length);
  }, [numberOfItemsToShow, activeItemIndex]);

  const showLeftArrow = activeItemIndex !== 0;
  const showRightArrow = rightIndex < products.length;

  const showTopNavigation =
    !showMobileCarousel && variant !== 'center';

  const handleMoveLeft = (): void => {
    if (activeItemIndex !== 0) {
      const newIndex = Math.max(activeItemIndex - cardsOnPage, 0);
      setActiveItemIndex(newIndex);
      slideToItem(newIndex);
    }
  };

  const handleMoveRight = (): void => {
    const newIndex = Math.min(
      activeItemIndex + cardsOnPage,
      products.length - cardsOnPage,
    );

    setActiveItemIndex(newIndex);
    slideToItem(newIndex);
  };

  useListenToCustomEvent((event) => {
    if (event.eventName === 'onSlideChange') {
      setActiveItemIndex(event.currentItem.index);
    }
  });

  return (
    <div className="recentlyViewedProductsCarouselWrapper">
      <div
        className={`recentlyViewedProductsCarouselHeading ${
          variant === 'center' &&
          !showMobileCarousel &&
          'recentlyViewedProductsCarouselHeadingCenter'
        }`}
      >
        <h4 className="recentlyViewedProductsCarouselTitle">
          {carouselTitle}
        </h4>
        {showTopNavigation && (
          <div className="recentlyViewedProductsCarouselTopNavigation">
            <h5>
              {`Showing ${leftIndex} - ${rightIndex} of ${products.length} items`}
            </h5>
            <button
              aria-label="move carousel left"
              className="recentlyViewedProductCarouselButton"
              onClick={slideToPrevItem}
              type="button"
            >
              <Icon
                size="sm"
                name="icon-chevron-left"
                variant={showLeftArrow ? 'primary' : 'light-grey'}
              />
            </button>
            <button
              aria-label="move carousel right"
              className="recentlyViewedProductCarouselButton"
              onClick={slideToNextItem}
              type="button"
            >
              <Icon
                size="sm"
                name="icon-chevron-right"
                variant={showRightArrow ? 'primary' : 'light-grey'}
              />
            </button>
          </div>
        )}
      </div>
      {carouselFragment}
      {variant === 'center' && !showMobileCarousel && (
        <div className="recentlyViewedProductsCarouselBottomNavigation">
          <button
            aria-label="move carousel left"
            className="recentlyViewedProductCarouselButton"
            disabled={activeItemIndex === 0}
            onClick={handleMoveLeft}
            type="button"
          >
            <Icon
              size="sm"
              name="icon-chevron-left"
              variant={showLeftArrow ? 'primary' : 'light-grey'}
            />
          </button>
          <h5>
            {`Showing ${leftIndex} - ${rightIndex} of ${products.length} items`}
          </h5>
          <button
            aria-label="move carousel right"
            className="recentlyViewedProductCarouselButton"
            disabled={rightIndex === products.length}
            onClick={handleMoveRight}
            type="button"
          >
            <Icon
              size="sm"
              name="icon-chevron-right"
              variant={showRightArrow ? 'primary' : 'light-grey'}
            />
          </button>
        </div>
      )}
    </div>
  );
};

ReactSpringCenterCarousel.defaultProps = {
  variant: 'default',
};

export { ReactSpringCenterCarousel };
