import React from 'react';
import Link from 'next/link';
import { PERCENTAGE_MULTIPLIER } from '../../utilities/constants';

export type CarouselItemType = {
  id?: number;
  brand: string;
  condition: string;
  discountedPrice: string;
  image: { thumb: string } | string;
  price: string;
  productId?: number;
  slug: string;
  title: string;
  titleWithoutBrand: string;
  discountedTier?: number;
};

const formatCondition = (condition: string): string => {
  const formattedCondition =
    condition === 'very_good'
      ? 'Very good'
      : `${condition?.charAt(0).toUpperCase()}${condition
          ?.slice(1)
          .toLowerCase()}`;
  return formattedCondition;
};

const CarouselItem = ({
  product,
  variant = 'default',
}: {
  product: CarouselItemType;
  variant?: 'center' | 'default';
}): React.ReactElement => {
  const productIsDiscounted = product.discountedTier
    ? product.discountedTier > 0
    : +product.discountedPrice < +product.price;

  const imgSrc =
    typeof product.image === 'object'
      ? product.image.thumb
      : product.image;

  const isLoading = product.brand === 'loading';

  if (isLoading) {
    return (
      <div className="carouselItemContainer">
        <div className="carouselItemImage carouselItemImageLoading">
          <img alt={product.title} src={imgSrc} />
        </div>
      </div>
    );
  }

  return (
    <Link href={product.slug} passHref>
      <div className="carouselItemContainer">
        <div className="carouselItemImage">
          <img alt={product.title} src={imgSrc} />
        </div>
        <div
          className={`carouselItemDescription 
             ${variant === 'center' && 'carouselItemCenter'}
          `}
        >
          <p className="carouselItemDescriptionHeading">
            {product.brand}
          </p>
          <p className="carouselItemDescriptionTitle">
            {product.titleWithoutBrand}
          </p>
          <p className="carouselItemDescriptionCondition">
            {`Condition: ${formatCondition(product.condition)}`}
          </p>
          <div className="carouselItemDescriptionPrice">
            {productIsDiscounted && (
              <p className="carouselItemDescriptionInitialPrice">
                {`$${Number(product.price).toLocaleString()}`}
              </p>
            )}
            <p className="carouselItemDescriptionDiscountedPrice">
              {`$${Number(product.discountedPrice).toLocaleString()}`}
            </p>
            {productIsDiscounted && (
              <p>
                {`${(
                  (+product.price - +product.discountedPrice) /
                  (+product.price / PERCENTAGE_MULTIPLIER)
                ).toFixed()}%`}
              </p>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

CarouselItem.defaultProps = {
  variant: 'default',
};

export { CarouselItem };
