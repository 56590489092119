import { undefToNull } from '../../helpers';
import urlMapper from './segmentURLs.json';

/**
 * @param {{
 *  hasRecommendation: number;
 *  languageCode?: string;
 *  name: string;
 *  path?: string;
 *  referer?: string;
 *  search?: string;
 *  siteSection?: string;
 *  site_section?: string;
 *  title?: string;
 *  url?: string;
 *  userID: number;
 * }} pageData
 * @returns see schema/pageViewed
 */
export const pageViewedMapper = (pageData) => {
  let languageCode = pageData?.languageCode ?? null;
  if (!languageCode && typeof window !== 'undefined') {
    const LANGUAGE_CODES_HYPHENS = 2;
    languageCode =
      window.navigator.language?.slice(0, LANGUAGE_CODES_HYPHENS) ||
      null;
  }

  let name = pageData?.name;
  if (name?.includes('|')) {
    // eslint-disable-next-line prefer-destructuring
    name = name.split('|')[0]?.trim();
  }
  let title = document?.title || name || '';
  if (title?.includes('|')) {
    title = title.split('|')[0]?.trim();
  }
  let urlData = null;
  if (typeof window !== 'undefined') {
    urlData =
      urlMapper[window.location.pathname] ||
      urlMapper[`${window.location.pathname}/`];

    if (window.location.search.includes('search=')) {
      urlData = urlMapper['/shop?search='];
    }
  }
  let useName = urlData?.name || name || 'Unknown';
  if (useName.includes('- Shop')) {
    useName = useName.split('- Shop').join('-');
  }

  return undefToNull({
    has_recommendation: pageData?.hasRecommendation,
    language_code: languageCode,
    name: useName,
    site_section: pageData?.siteSection || 'Shopping',
    title,
    user_id: pageData?.userID,
  });
};
