import React from 'react';
import Head from 'next/head';
import { isEmpty } from 'lodash';
import { Tags } from '../types/Store';

const metaTags = ({
  tags,
}: {
  tags: Partial<Tags>;
}): React.ReactElement => {
  if (isEmpty(tags)) {
    return <></>;
  }
  return (
    <Head>
      {tags.title ? <title>{tags.title}</title> : null}
      {tags.description ? (
        <meta name="description" content={tags.description} />
      ) : null}
      {tags.keywords ? (
        <meta name="keywords" content={tags.keywords} />
      ) : null}
      {tags.ogTitle ? (
        <meta property="og:title" content={tags.ogTitle} />
      ) : null}
      {tags.ogImage ? (
        <meta
          property="og:image"
          content={
            typeof tags.ogImage !== 'string'
              ? tags.ogImage?.fields?.file?.url
              : tags.ogImage
          }
        />
      ) : null}
      {tags.ogImageWidth ? (
        <meta property="og:image:width" content={tags.ogImageWidth} />
      ) : null}
      {tags.ogImageHeight ? (
        <meta
          property="og:image:height"
          content={tags.ogImageHeight}
        />
      ) : null}
      {tags.ogType ? (
        <meta property="og:type" content={tags.ogType} />
      ) : null}
      <meta property="og:site_name" content="fashionphile.com" />
      {tags.canonical ? (
        <meta
          property="og:url"
          content={tags.canonical.toLowerCase()}
        />
      ) : null}
      {tags.ogDescription ? (
        <meta
          property="og:description"
          content={tags.ogDescription}
        />
      ) : null}
      {tags.fbAdmins ? (
        <meta property="fb:admins" content={tags.fbAdmins} />
      ) : null}
      {tags.canonical ? (
        <link rel="canonical" href={tags.canonical.toLowerCase()} />
      ) : null}
      {tags.noindex ? <meta name="robots" content="noindex" /> : null}
      {tags.nofollow && tags.noindex ? (
        <meta name="robots" content="noindex, nofollow" />
      ) : null}
    </Head>
  );
};

export default metaTags;
